import React from 'react';
import { Helmet } from 'react-helmet';

export function OG({
  title,
  description,
  image: imagePath,
  locale = 'en',
}: {
  title: string;
  description: string;
  image: string;
  locale?: string;
}) {
  const image = `https://aurory.io${imagePath}`;

  return (
    <Helmet>
      {!locale ? null : <html lang={locale} />}
      <meta charSet='utf-8' />
      <meta name='theme-color' content='#000000' />
      <title>{title}</title>
      <meta name='description' content={description} />
      <meta property='twitter:card' content='summary' />
      <meta property='twitter:site' content='@AuroryProject' />
      <meta property='twitter:title' content={title} />
      <meta property='twitter:description' content={description} />
      <meta property='twitter:image' content={image} />
      <meta property='og:title' content={title} />
      <meta property='og:description' content={description} />
      <meta property='og:image' content={image} />
      {/*
      <meta property='og:url' content={window.location.href} />
      <meta property="og:image:secure_url" content={image} />
      <meta property="og:image:type" content="image/jpeg" />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="300" /> 
      */}
    </Helmet>
  );
}
