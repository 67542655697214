export const ROOT = '/';
export const ABOUT_US = '/about-us';
export const PRESS_RELEASES = '/press-releases/';
export const WHITEPAPER = 'https://docs.aurory.io/aurory-whitepaper/';
export const PATCH_NOTES = '/news';
export const FAQ = 'https://docs.aurory.io/aurory-faqs/';
export const SUPPORT = 'https://docs.aurory.io/aurory-faqs/support';
export const DISCORD = 'https://discord.gg/aurory';
export const TWITTTER = 'https://twitter.com/AuroryProject';
export const BLOG =
  'https://aurory.substack.com/?utm_source=substack&utm_medium=web&utm_campaign=reader2&utm_source=%2Fsearch%2Faurory&utm_medium=reader2';
export const TELEGRAM = 'https://t.me/aurory_project';
export const YOUTUBE =
  'https://www.youtube.com/channel/UCcJNmpgOE3CpqQls8UH1mRw';
export const INSTAGRAM = 'https://www.instagram.com/auroryproject/';
export const TIKTOK = 'https://www.tiktok.com/@auroryproject';
export const PRIVACY_POLICY = '/privacy-policy';
export const TERMS_OF_USE = '/terms-of-use';
export const CODE_OF_CONDUCT = '/code-of-conduct';
export const APP = 'https://app.aurory.io/';
export const MARKETPLACE = 'https://app.aurory.io/marketplace';
export const SWAP = 'https://app.aurory.io/buy-aury';
export const SOT = 'https://app.aurory.io/seekers-of-tokane';
export const AMPLIFIER = 'https://app.aurory.io/amplifier';
export const EVENTS = 'https://app.aurory.io/events';
export const INCUBATOR = 'https://app.aurory.io/incubator';
export const TEMPLE = 'https://app.aurory.io/temple';
export const GALLERY = 'https://app.aurory.io/aurorians';
export const TEMPLE_KIN = 'https://app.aurory.io/temple/kin';
export const LEADERBOARD = 'https://app.aurory.io/leaderboard';

export function getNewsDetailRoute(slug: string) {
  return `/news/${slug}`;
}
