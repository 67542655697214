import React, { useMemo } from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import {
  CookieConsentBanner,
  CookieConsenti18N,
} from '@/components/shared/CookieConsentBanner';
import { AnalyticsProvider } from '@/contexts/analytics';
import { EventsProvider } from '@/contexts/events';
import HeaderTopbar, {
  HeaderI18N,
} from '@/components/shared/HeaderTopbar/HeaderTopbar';
import Footer, { Footeri18N } from '@/components/shared/Footer';
import Notifications from '@/components/shared/Notifications';
import CacheReferralCode from '@/components/shared/CacheReferralCode';
import UTMTags from '@/components/shared/UTMTags/UTMTags';
import { LocaleProvider } from '@/contexts/locale';
import { DEFAULT_LOCALE } from '@/config/locales';
import { OG } from '@/components/shared/OG/OG';

interface ILayoutProps {
  children: React.ReactNode;
  params: {
    locale: string;
  };
  location: Location;
}

type Data = {
  layout: {
    nodes: {
      locale: string;
      header: HeaderI18N;
      cookieConsent: CookieConsenti18N;
      footer: Footeri18N;
    }[];
  };
};

const Layout: React.FC<ILayoutProps> = ({ children, params, location }) => {
  const locale = params.locale || DEFAULT_LOCALE;

  const data: Data = useStaticQuery(graphql`
    query cookieConsentQueryAndHeaderQuery {
      layout: allLayoutYaml {
        nodes {
          locale
          header {
            topBar {
              marketCap
            }
            menu {
              playNow
              marketplace
              patchNotes
              news
              pressReleases
              faq
              aboutUs
              gameDashboard
              more
              whitePaper
            }
          }
          cookieConsent {
            message
            accept
            decline
          }
          footer {
            whitepaper
            support
            codeOfConduct
            pressReleases
            aboutAurory
            patchNotes
            news
            privacy
            termsOfService
            copyright
            byAurory
          }
        }
      }
    }
  `);

  const enI18n = React.useMemo(
    () => data.layout.nodes.find((i18n) => i18n.locale === 'en'),
    [data]
  );

  const localeI18n = React.useMemo(
    () =>
      locale === 'en'
        ? null
        : data.layout.nodes.find((i18n) => i18n.locale === locale),
    [data, locale]
  );

  const i18n = useMemo(() => (localeI18n || enI18n)!, [localeI18n, enI18n]);

  return (
    <>
      <OG
        title='Aurory Project, a Solana-based blockchain gaming studio bringing you play-to-own and NFTs!'
        description='The retro-futuristic universe called Antik is a rich and diverse universe populated by Nefties. '
        image='/og.jpg'
        locale={locale}
      />
      <AnalyticsProvider {...{ location }}>
        <EventsProvider>
          <LocaleProvider {...{ locale }}>
            <HeaderTopbar i18n={i18n.header} />
            <main>
              {children}
              <CookieConsentBanner i18n={i18n.cookieConsent} />
              <Notifications />
              <CacheReferralCode {...{ location }} />
              <UTMTags />
            </main>
            <footer>
              <Footer i18n={i18n.footer} />
            </footer>
          </LocaleProvider>
        </EventsProvider>
      </AnalyticsProvider>
    </>
  );
};

export default Layout;
